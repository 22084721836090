<template>
  <div>
    <b-form @submit.stop.prevent="formOnsubmit">
      <div class="card">
        <div class="card-body">
          <!-- Name input -->
          <b-form-group
            id="input-group-name"
            label="Nama:"
            label-for="input-name"
          >
            <b-form-input
              id="input-name"
              v-model="form.name"
              placeholder="Nama..."
            ></b-form-input>
            <small class="text-danger">{{ error.name }}</small>
          </b-form-group>

          <div class="row">
            <div class="col-md-6">
              <!-- price -->
              <b-form-group
                id="input-group-price"
                label="Harga:"
                label-for="input-price"
              >
                <b-form-input
                  id="input-price"
                  inputmode="numeric"
                  v-model="formatedPrice"
                  @keyup="priceOnKeyup"
                  placeholder="Harga..."
                ></b-form-input>
                <small class="text-danger">{{ error.price }}</small>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <!-- payment category type -->
              <b-form-group id="input-group-category-type">
                <label for="Tipe"
                  >Tipe: <em class="text-muted">opsional</em></label
                >
                <b-form-select
                  id="input-category-type"
                  v-model="form.payment_category_type_id"
                  :options="categoryTypes"
                ></b-form-select>
                <small class="text-danger">{{
                  error.payment_category_type_id
                }}</small>
              </b-form-group>
            </div>
          </div>
        </div>
      </div>

      <div class="card my-2">
        <div class="card-body py-4">
          <div class="row">
            <div class="col-md-6">
              <label for="isBundle"
                >Produk Bundle <em class="text-muted">opsional</em></label
              >
            </div>

            <div class="col-md-6 d-flex justify-content-end">
              <b-form-checkbox
                v-model="form.isBundle"
                name="check-button"
                switch
                size="lg"
              >
                <i class="fas fa-check text-success" v-if="form.isBundle"></i>
                <i
                  class="fas fa-times text-danger"
                  v-if="form.isBundle == false"
                ></i>
              </b-form-checkbox>
            </div>
          </div>
        </div>
      </div>

      <transition name="fade" mode="out-in">
        <div v-if="form.isBundle">
          <div class="card my-2">
            <div class="card-body">
              <div class="row">
                <div class="col-md-6">
                  <span><b>Daftar Produk Terpilih</b></span>
                </div>
                <div class="col-md-6 d-flex justify-content-end">
                  <button
                    type="button"
                    class="btn btn-purple"
                    @click="$bvModal.show('modal-payment-categories')"
                  >
                    Pilih Produk
                  </button>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <b-table
                    striped
                    hover
                    class="mt-3"
                    :items="selectedItem"
                    :fields="fields"
                  >
                    <template #table-colgroup="scope">
                      <col
                        v-for="field in scope.fields"
                        :key="field.key"
                        :style="{ width: field.key === 'actions' ? '20%' : '' }"
                      />
                    </template>
                    <template #cell(number)="data">
                      {{ data.index + 1 }}
                    </template>
                    <template #cell(quantity)="data">
                      <div class="row justify-content-center">
                        <div class="col-md-12 col-sm-12">
                          <b-button
                            size="sm"
                            class="btn-info"
                            @click="minusQty(data.item.id)"
                          >
                            <i class="fas fa-minus px-0"></i>
                          </b-button>
                          <b-button size="sm" class="btn btn-white">{{
                            parseInt(data.item.quantity).toLocaleString("id-ID")
                          }}</b-button>
                          <b-button
                            size="sm"
                            class="btn-info"
                            @click="addQty(data.item.id)"
                          >
                            <i class="fas fa-plus px-0"></i>
                          </b-button>
                        </div>
                      </div>
                    </template>
                    <template #cell(price)="data">
                      {{ parseInt(data.item.price).toLocaleString("id-ID") }}
                    </template>
                    <template #cell(actions)="data">
                      <div class="d-flex">
                        <template>
                          <b-button
                            size="sm"
                            class="btn-danger"
                            v-b-tooltip.hover
                            title="Hapus"
                            @click="btnDeleteService(data.item.id)"
                            ><i class="fas fa-trash px-0"></i
                          ></b-button>
                        </template>
                      </div>
                    </template>
                  </b-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>

      <transition name="fade" mode="out-in">
        <div v-if="form.isBundle == false">
          <div class="card my-2">
            <div class="card-body">
              <div class="card-title row">
                <div class="col-md-6">
                  <span><b>Obat & Alkes (Apotek)</b></span>
                </div>

                <div class="col-md-6 d-flex justify-content-end">
                  <button
                    type="button"
                    class="btn btn-purple"
                    @click="$bvModal.show('modal-medicine')"
                  >
                    Pilih Obat & Alkes
                  </button>
                </div>
              </div>
              <b-table
                striped
                hover
                class="mt-3"
                responsive
                :items="coll_medicine"
                :fields="fieldsMedicine"
                thead-class="bg-secondary"
              >
                <template #table-colgroup="scope">
                  <col
                    v-for="field in scope.fields"
                    :key="field.key"
                    :style="{ width: field.key === 'actions' ? '20%' : '' }"
                  />
                </template>
                <template #cell(number)="data">
                  {{ data.index + 1 }}
                </template>
                <template #cell(price)="data">
                  {{ parseInt(data.item.price).toLocaleString("id-ID") }}
                </template>
                <template #cell(actions)="data">
                  <div class="d-flex">
                    <template>
                      <b-button
                        size="sm"
                        class="btn-danger"
                        v-b-tooltip.hover
                        title="Hapus"
                        @click="btnDeleteOnClick(data.item.id)"
                        ><i class="fas fa-trash px-0"></i
                      ></b-button>
                    </template>
                  </div>
                </template>
              </b-table>
            </div>
          </div>

          <div class="card my-2">
            <div class="card-body">
              <div class="card-title row">
                <div class="col-md-6">
                  <span><b>Bahan Pendukung (inventory)</b></span>
                </div>

                <div class="col-md-6 d-flex justify-content-end">
                  <button
                    type="button"
                    class="btn btn-purple"
                    @click="$bvModal.show('modal-inventory-payment-categories')"
                  >
                    Pilih Item
                  </button>
                </div>
              </div>
              <b-table
                hover
                class="mt-3"
                responsive
                :items="coll_inventory"
                :fields="fieldsInventory"
                thead-class="bg-secondary"
              >
                <template #table-colgroup="scope">
                  <col
                    v-for="field in scope.fields"
                    :key="field.key"
                    :style="{ width: field.key === 'actions' ? '20%' : '' }"
                  />
                </template>
                <template #cell(number)="data">
                  {{ data.index + 1 }}
                </template>
                <template #cell(price)="data">
                  {{ parseInt(data.item.price).toLocaleString("id-ID") }}
                </template>

                <template #cell(actions)="data">
                  <div class="d-flex">
                    <template>
                      <b-button
                        size="sm"
                        class="btn-success mx-1"
                        v-b-tooltip.hover
                        title="Ubah"
                        @click="
                          btnEditInventoryItem(
                            data.item.id,
                            data.item.inventory_id,
                            data.item.inventory_qty,
                            data.item.inventory_uom
                          )
                        "
                        ><i class="fas fa-edit px-0"></i
                      ></b-button>
                      <b-button
                        size="sm"
                        class="btn-danger mx-1"
                        v-b-tooltip.hover
                        title="Hapus"
                        @click="btnDeleteInventoryItem(data.item.id)"
                        ><i class="fas fa-trash px-0"></i
                      ></b-button>
                    </template>
                  </div>
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </transition>

      <div class="card">
        <div class="card-body">
          <!-- employee commission -->
          <b-form-group
            id="input-group-employee-commission"
            :label="`Komisi Pegawai (${employeeIsPercent ? '%' : 'Rp'}):`"
            label-for="input-employee-commission"
          >
            <b-input-group>
              <template #prepend>
                <b-input-group-text
                  squared
                  class="bg-light-primary font-weight-bold text-primary"
                  >{{ employeeIsPercent ? "%" : "Rp" }}</b-input-group-text
                >
                <!-- <b-input-group-text
                  squared
                  @click="btnIsEmployeePercentToggle"
                  class="bg-light-primary font-weight-bold text-primary"
                  >{{ employeeIsPercent ? "%" : "Rp" }}</b-input-group-text
                > -->
              </template>
              <b-form-input
                id="input-employee-commission"
                v-model="formatedEmployeeCommission"
                placeholder="Komisi Pegawai..."
              ></b-form-input>
            </b-input-group>
            <small class="text-danger">{{ error.employee_commission }}</small>
          </b-form-group>

          <!-- description -->
          <b-form-group id="input-group-description">
            <label for="input-description"
              >Deskripsi <em class="text-muted">opsional</em></label
            >
            <b-form-textarea
              id="input-group-description"
              v-model="form.description"
              placeholder="Deskripsi..."
              rows="3"
              max-rows="6"
            ></b-form-textarea>
            <small class="text-danger">{{ error.description }}</small>
          </b-form-group>
        </div>
      </div>

      <div class="card my-2">
        <div class="card-body py-4">
          <!-- Submit & Cancel button -->
          <b-button type="submit" variant="primary">Simpan</b-button>
          <b-button
            type="button"
            class="ml-2"
            variant="default"
            @click="$router.push('/payment-categories/list')"
          >
            Batal
          </b-button>
        </div>
      </div>
    </b-form>

    <!-- modal quantity medicine -->
    <b-modal id="modal-qty" hide-footer size="md" title="Jumlah Pemakaian">
      <b-form>
        <!-- <div class="d-block text-center"> -->
        <div class="row">
          <div class="col-md-12">
            <b-input-group class="my-2">
              <!-- <template #append>
                <b-input-group-text
                  >Sisa Stok : {{ medicine_form.stock }}</b-input-group-text
                >
              </template> -->

              <!-- <b-form-group
                    id="input-group-name"
                    label="Nama Item:"
                    label-for="input-name"
                  > -->
              <b-form-input
                id="input-name"
                v-model="medicine_form.quantity"
                placeholder="Nama Item"
              ></b-form-input>
              <!-- <b-form-input
                id="input-name"
                v-model="medicine_form.quantity"
                placeholder="Nama Item"
                @keyup="inputQty"
              ></b-form-input> -->
              <!-- </b-form-group> -->
            </b-input-group>
            <small class="text-danger">{{ message }}</small>
          </div>
        </div>

        <b-button type="button" @click="saveQty" variant="primary"
          >Simpan</b-button
        >
        <b-button
          type="button"
          class="ml-2"
          variant="default"
          @click="hideModalCancel"
        >
          Cancel
        </b-button>
      </b-form>
    </b-modal>

    <!-- modal quantity inventory -->
    <b-modal
      id="modal-qty-inventory"
      hide-footer
      size="md"
      title="Jumlah Pemakaian"
    >
      <b-form>
        <!-- <div class="d-block text-center"> -->
        <div class="row">
          <div class="col-md-12">
            <b-input-group class="my-2">
              <template #append>
                <b-input-group-text>{{
                  inventory_form.uom
                }}</b-input-group-text>
              </template>

              <!-- <b-form-group
                    id="input-group-name"
                    label="Nama Item:"
                    label-for="input-name"
                  > -->
              <b-form-input
                id="input-name"
                v-model="inventory_form.quantity"
                placeholder="Nama Item"
              ></b-form-input>
              <!-- <b-form-input
                id="input-name"
                v-model="medicine_form.quantity"
                placeholder="Nama Item"
                @keyup="inputQty"
              ></b-form-input> -->
              <!-- </b-form-group> -->
            </b-input-group>
            <small class="text-danger">{{ message }}</small>
          </div>
        </div>

        <b-button type="button" @click="saveQtyInventory" variant="primary"
          >Simpan</b-button
        >
        <b-button
          type="button"
          class="ml-2"
          variant="default"
          @click="hideModalCancel"
        >
          Cancel
        </b-button>
      </b-form>
    </b-modal>
    
    <b-modal
      id="modal-qty-inventory-update"
      hide-footer
      size="md"
      title="Jumlah Pemakaian"
    >
      <b-form>
        <!-- <div class="d-block text-center"> -->
        <div class="row">
          <div class="col-md-12">
            <b-input-group class="my-2">
              <template #append>
                <b-input-group-text>{{
                  inventory_form.uom
                }}</b-input-group-text>
              </template>

              <!-- <b-form-group
                    id="input-group-name"
                    label="Nama Item:"
                    label-for="input-name"
                  > -->
              <b-form-input
                id="input-name"
                v-model="inventory_form.quantity"
                placeholder="Nama Item"
              ></b-form-input>
              <!-- <b-form-input
                id="input-name"
                v-model="medicine_form.quantity"
                placeholder="Nama Item"
                @keyup="inputQty"
              ></b-form-input> -->
              <!-- </b-form-group> -->
            </b-input-group>
            <small class="text-danger">{{ message }}</small>
          </div>
        </div>

        <b-button type="button" @click="saveEditQtyInventory" variant="primary"
          >Simpan</b-button
        >
        <b-button
          type="button"
          class="ml-2"
          variant="default"
          @click="hideModalCancel"
        >
          Cancel
        </b-button>
      </b-form>
    </b-modal>

    <ModalPaymentCategories
      :selectedItem="selectedItem"
      :edit="true"
    ></ModalPaymentCategories>

    <ModalMedicine
      @chosenMedicine="setPrescription"
      :selectedItem="coll_medicine"
      :checkbox="false"
      :isSortcut="false"
      :isAbjad="true"
    />

    <ModalInventory
      @chosenItem="setInventory"
      :selectedItem="coll_inventory"
      :checkbox="false"
    />
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import ModalPaymentCategories from "@/component/masters/payment-categories/ModalPaymentCategories.vue";
import ModalMedicine from "@/component/general/ModalMedicineService.vue";
import ModalInventory from "@/component/general/ModalInventoryProductCategory.vue";
export default {
  props: {
    form: Object,
    route: String,
    purpose: String,
  },

  components: {
    ModalPaymentCategories,
    ModalMedicine,
    ModalInventory,
  },

  data() {
    return {
      // data
      categoryTypes: [],
      // Error
      error: {
        name: "",
        price: "",
        description: "",
        payment_category_type_id: "",
        doctor_commission: "",
        hospital_income: "",
        employee_commission: "",
      },
      // other
      totalPercentage: 100,
      isPercent: false,
      employeeIsPercent: false,

      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "name",
          label: "Nama",
          sortable: true,
        },
        {
          key: "price",
          label: "Harga",
          sortable: true,
        },
        {
          key: "quantity",
          label: "Jumlah",
          sortable: true,
        },
        // {
        //   key: "doctor_commission",
        //   label: "Komisi Dokter",
        //   sortable: true,
        // },
        // {
        //   key: "hospital_income",
        //   label: "Pendapatan Rumah Sakit",
        //   sortable: true,
        //   thClass: 'text-truncate',
        //   thStyle: { maxWidth: '90px', overflowX: 'auto' }
        // },
        { key: "actions", label: "" },
      ],
      fieldsMedicine: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "name",
          label: "Nama",
          sortable: true,
        },
        {
          key: "price",
          label: "Harga",
          sortable: true,
        },
        {
          key: "quantity",
          label: "Jumlah",
          sortable: true,
        },

        { key: "actions", label: "" },
      ],

      fieldsInventory: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "inventory_name",
          label: "Item",
          sortable: true,
        },
        {
          key: "inventory_uom",
          label: "Satuan",
          sortable: true,
        },
        {
          key: "inventory_qty",
          label: "Jumlah",
          sortable: true,
        },

        { key: "actions", label: "" },
      ],
      selectedItem: [],
      deletedService: [],

      // medicines
      medicine_form: {
        indexId: "",
        id: "",
        name: "",
        price: 0,
        quantity: 0,
        stock: 0,
      },

      // inventories
      inventory_form: {
        indexId: "",
        id: "",
        name: "",
        price: 0,
        quantity: 0,
        uom: "",
        stock: 0,
      },

      coll_medicine: [],
      deleted_coll_medicine: [],
      coll_medicine_str: "",

      coll_inventory: [],
      deleted_coll_inventory: [],
      coll_inventory_str: "",

      display: {
        medicine_name: "",
      },

      // other
      message: "",
      temporaryVal: [],
    };
  },

  computed: {
    formatedPrice: {
      set(newValue) {
        newValue = parseInt(newValue.replace(/\D/g, ""), 10);
        if (!Number.isNaN(newValue)) {
          this.form.price = newValue.toLocaleString("id-ID");
        } else {
          this.form.price = "";
        }
      },
      get() {
        return this.form.price;
      },
    },

    formatedDoctorCommission: {
      set(newValue) {
        newValue = parseInt(newValue.replace(/\D/g, ""), 10);
        if (!Number.isNaN(newValue)) {
          this.form.doctor_commission = newValue.toLocaleString("id-ID");
        } else {
          this.form.doctor_commission = "";
        }
      },
      get() {
        return this.form.doctor_commission;
      },
    },

    formatedHospitalIncome: {
      set(newValue) {
        newValue = parseInt(newValue.replace(/\D/g, ""), 10);
        if (!Number.isNaN(newValue)) {
          this.form.hospital_income = newValue.toLocaleString("id-ID");
        } else {
          this.form.hospital_income = "";
        }
      },
      get() {
        return this.form.hospital_income;
      },
    },

    formatedEmployeeCommission: {
      set(newValue) {
        newValue = parseInt(newValue.replace(/\D/g, ""), 10);
        if (!Number.isNaN(newValue)) {
          if (newValue > parseInt(this.form.price.replace(/\D/g, ""), 10)) {
            this.form.employee_commission = this.form.price;
          } else {
            this.form.employee_commission = newValue.toLocaleString("id-ID");
          }
        } else {
          this.form.employee_commission = "";
        }
      },
      get() {
        return this.form.employee_commission;
      },
    },
  },

  methods: {
    // medicine
    async setPrescription(value, data) {
      this.medicine_form.id = value.id;
      this.medicine_form.name = value.name;
      this.medicine_form.price = value.sales_price;
      this.medicine_form.stock = value.stock;

      this.$bvModal.show("modal-qty");
    },

    hideModalCancel() {
      this.medicine_form.id = "";
      this.medicine_form.name = "";
      this.medicine_form.price = 0;
      this.medicine_form.quantity = 0;
      this.$bvModal.hide("modal-qty");
    },

    // inventory
    async setInventory(value, data) {
      this.inventory_form.id = value.id;
      this.inventory_form.name = value.name;
      this.inventory_form.price = value.sales_price;
      this.inventory_form.stock = value.stock;
      this.inventory_form.uom = value.uom;

      this.$bvModal.show("modal-qty-inventory");
    },

    hideModalCancelInventory() {
      this.inventory_form.id = "";
      this.inventory_form.name = "";
      this.inventory_form.price = 0;
      this.inventory_form.quantity = null;
      this.inventory_form.uom = "";
      this.$bvModal.hide("modal-qty-inventory");
    },

    addQty(val) {
      let index = this.selectedItem.findIndex((x) => x.id == val);

      this.selectedItem[index].quantity += 1;
    },

    minusQty(val) {
      let index = this.selectedItem.findIndex((x) => x.id == val);

      if (
        this.selectedItem[index].quantity < 1 &&
        this.selectedItem[index].quantity == 0
      ) {
        Swal.fire("Perhatian", "Jumlah tidak boleh kurang dari 1", "warning");
        this.selectedItem[index].quantity = 1;
      } else {
        this.selectedItem[index].quantity -= 1;
      }
    },

    // inputQty() {
    //   if (this.medicine_form.quantity > this.medicine_form.stock) {
    //     this.message = "Jumlah yang dimasukkan melebihi sisa stok";
    //     this.medicine_form.quantity = 0;
    //   } else {
    //     this.message = "";
    //   }
    // },

    saveQty() {
      let exist = this.coll_medicine.findIndex(
        (x) => x.id == this.medicine_form.id
      );

      if (exist == -1) {
        let clone = { ...this.medicine_form };
        this.coll_medicine.push(clone);
      }

      this.medicine_form.id = "";
      this.medicine_form.name = "";
      this.medicine_form.price = 0;
      this.medicine_form.quantity = 0;

      this.$bvModal.hide("modal-qty");
      this.$bvModal.hide("modal-medicine");
    },

    async saveQtyInventory() {
      // FIRST METHOD
      // if(this.inventory_form.quantity == null){
      //   this.inventory_form.quantity = 0
      // }

      // let exist = this.coll_inventory.findIndex(
      //   (x) => x.id == this.inventory_form.id
      // );

      // if (exist == -1) {
      //   let clone = { ...this.inventory_form };
      //   this.coll_inventory.push(clone);
      // }

      // this.inventory_form.id = "";
      // this.inventory_form.name = "";
      // this.inventory_form.price = 0;
      // this.inventory_form.quantity = null;

      // SECOND METHOD
      this.medicine_form._method = "post";
      if (this.inventory_form.quantity == null) {
        this.inventory_form.quantity = 0;
      }

      this.inventory_form.payment_category_id = this.$route.params.id;
      this.inventory_form.inventory_id = this.inventory_form.id;
      

      // save data
      let response = await module.submit(
        this.inventory_form,
        "payment-category-inventories"
      );

      // update table list
      if (response.state == "success") {
        this.getDataInventory();

        this.inventory_form.indexId = "";
        this.inventory_form.id = "";
        this.inventory_form.name = "";
        this.inventory_form.price = 0;
        this.inventory_form.quantity = 0;
      }

      // close modal
      this.$bvModal.hide("modal-qty-inventory");
      this.$bvModal.hide("modal-inventory-payment-categories");
    },

    async getDataMedicine() {
      let result = await module.get(
        "payment-categories-medicine/" + this.$route.params.id
      );

      result.forEach((el) => {
        this.medicine_form.indexId = el.id;
        this.medicine_form.id = el.medicine_id;
        this.medicine_form.name = el.medicine_name;
        this.medicine_form.price = el.medicine_price;
        this.medicine_form.quantity = el.medicine_qty;

        let clone = { ...this.medicine_form };
        this.coll_medicine.push(clone);
      });

      this.medicine_form.indexId = "";
      this.medicine_form.id = "";
      this.medicine_form.name = "";
      this.medicine_form.price = 0;
      this.medicine_form.quantity = 0;
    },

    async getDataInventory() {
      let result = await module.get(
        "payment-categories-inventory/" + this.$route.params.id
      );

      // FIRST METHOD
      // result.forEach((el) => {
      //   this.inventory_form.indexId = el.id;
      //   this.inventory_form.id = el.inventory_id;
      //   this.inventory_form.name = el.inventory_name;
      //   this.inventory_form.uom = el.inventory_uom;
      //   // this.inventory_form.price = el.inventory_price;
      //   this.inventory_form.quantity = el.inventory_qty;

      //   let clone = { ...this.inventory_form };
      //   this.coll_inventory.push(clone);
      // });

      // this.inventory_form.indexId = "";
      // this.inventory_form.id = "";
      // this.inventory_form.name = "";
      // this.inventory_form.uom = "";
      // // this.inventory_form.price = 0;
      // this.inventory_form.quantity = 0;

      // SECOND METHOD
      this.coll_inventory = result;
    },

    async btnDeleteOnClick(id, index) {
      let a;
      for (a = 0; a < this.coll_medicine.length; a++) {
        if (this.coll_medicine[a].id == id) {
          this.deleted_coll_medicine.push(this.coll_medicine[a]);
          this.coll_medicine.splice(a, 1);
        }
      }
    },

    async btnDeleteInventoryItem(id, index) {
      // FIRST METHOD
      // let a;
      // for (a = 0; a < this.coll_inventory.length; a++) {
      //   if (this.coll_inventory[a].id == id) {
      //     this.deleted_coll_inventory.push(this.coll_inventory[a]);
      //     this.coll_inventory.splice(a, 1);
      //   }
      // }

      // SECOND METHOD
      let result = await module.deleteNoFire(
        "payment-category-inventories/" + id
      );

      if (result == true) {
        this.getDataInventory();

        this.medicine_form.indexId = "";
        this.medicine_form.id = "";
        this.medicine_form.name = "";
        this.medicine_form.price = 0;
        this.medicine_form.quantity = 0;
      }
    },

    async btnEditInventoryItem(id,IId, qty, uom) {
      this.inventory_form.id = id
      this.inventory_form.inventory_id = IId;
      this.inventory_form.payment_category_id = this.$route.params.id;
      this.inventory_form.quantity = qty;
      this.inventory_form.uom = uom;
      this.inventory_form._method = "put";

      this.$bvModal.show("modal-qty-inventory-update");
    },

    async saveEditQtyInventory() {

      let result = await module.submit(this.inventory_form,
        "payment-category-inventories/" + this.inventory_form.id 
      );

      

      if(result.state == "success"){
        this.$bvModal.hide("modal-qty-inventory-update");
        this.getDataInventory();

        this.inventory_form.indexId = "";
        this.inventory_form.id = "";
        this.inventory_form.name = "";
        this.inventory_form.price = 0;
        this.inventory_form.quantity = 0;
        this.inventory_form._method = "post";
      }
    },

    // end medicine

    // service
    async btnDeleteService(id, index) {
      let found = this.selectedItem.findIndex((x) => x.id == id);
      this.deletedService.push(this.selectedItem[found]);
      this.selectedItem.splice(found, 1);
    },

    async getService() {
      let response = await module.get(
        "payment-categories-bundle/" + this.$route.params.id
      );
      response.forEach((el) => {
        let item = {
          indexId: el.id,
          id: el.payment_categories_id,
          name: el.payment_categories_name,
          price: el.payment_categories_price,
          quantity: el.quantity,
        };
        this.selectedItem.push(item);
      });
    },

    // end service
    async getType() {
      let response = await module.setSelectOption("payment-category-types");
      this.categoryTypes = response.data;
      this.categoryTypes.unshift({
        text: "Pilih Tipe",
        value: "",
        disabled: true,
      });
    },

    btnIsPercentToggle() {
      this.error.doctor_commission = "";
      this.error.hospital_income = "";
      this.totalPercentage = 100;
      this.form.doctor_commission = "";
      this.form.hospital_income = "";
      this.isPercent ? (this.isPercent = false) : (this.isPercent = true);
    },

    priceOnKeyup(evt) {
      if (
        (evt.keyCode >= 48 && evt.keyCode <= 57) ||
        (evt.keyCode >= 96 && evt.keyCode <= 105) ||
        evt.keyCode == 8
      ) {
        if (this.formatedPrice != 0) {
          if (this.isPercent) {
            this.totalPercentage = 100;
            this.form.doctor_commission = 50;
            this.form.hospital_income = 50;
          } else {
            let share = parseInt(this.form.price.replace(/\D/g, ""), 10) / 2;
            this.form.hospital_income = !isNaN(share)
              ? share.toLocaleString("id-ID")
              : "";
            this.form.doctor_commission = !isNaN(share)
              ? share.toLocaleString("id-ID")
              : "";
          }
        }
      }
    },

    doctorCommisionOnKeyUp() {
      if (this.formatedPrice != 0) {
        if (this.isPercent) {
          this.totalPercentage = 100;
          this.totalPercentage -= this.form.doctor_commission;
          this.form.hospital_income = this.totalPercentage;
        } else {
          let share = parseInt(this.form.price.replace(/\D/g, ""), 10);
          share -= parseInt(this.form.doctor_commission.replace(/\D/g, ""), 10);
          this.form.hospital_income = !isNaN(share)
            ? share.toLocaleString("id-ID")
            : "";
        }
      }
    },

    hospitalIncomeOnKeyUp() {
      if (this.formatedPrice != 0) {
        if (this.isPercent) {
          this.totalPercentage = 100;
          this.totalPercentage -= this.form.hospital_income;
          this.form.doctor_commission = this.totalPercentage;
        } else {
          let share = parseInt(this.form.price.replace(/\D/g, ""), 10);
          share -= parseInt(this.form.hospital_income.replace(/\D/g, ""), 10);
          this.form.doctor_commission = !isNaN(share)
            ? share.toLocaleString("id-ID")
            : "";
        }
      }
    },

    btnIsEmployeePercentToggle() {
      this.error.employee_commission = "";
      this.totalPercentage = 100;
      this.form.employee_commission = "";
      this.employeeIsPercent
        ? (this.employeeIsPercent = false)
        : (this.employeeIsPercent = true);
    },

    async formOnsubmit() {
      if (!this.isPercent) {
        let intPrice = parseInt(this.form.price.replace(/\D/g, ""), 10);
        let intDoctorCommission = parseInt(
          this.form.doctor_commission.replace(/\D/g, ""),
          10
        );
        let intHospitalIncome = parseInt(
          this.form.hospital_income.replace(/\D/g, ""),
          10
        );
        if (intPrice < intDoctorCommission || intPrice < intHospitalIncome) {
          Swal.fire(
            "Gagal",
            "Komisi Dokter / Pendapatan Rumah Sakit tidak boleh melebihi Harga",
            "warning"
          );
        } else {
          this.actualFormSubmit();
        }
      } else {
        this.actualFormSubmit();
      }
    },

    async actualFormSubmit() {
      let idData = [];
      let deletedIdData = [];

      this.selectedItem.forEach((val) => {
        idData.push(val);
      });
      this.deletedService.forEach((val) => {
        deletedIdData.push(val.id);
      });

      // Make Request
      this.form["is_percent"] = this.isPercent;
      this.form["services"] = JSON.stringify(idData);
      this.form["deleted_services"] = JSON.stringify(deletedIdData);
      this.form["medicines"] = JSON.stringify(this.coll_medicine);
      this.form["delete_medicines"] = JSON.stringify(
        this.deleted_coll_medicine
      );
      this.form["inventories"] = JSON.stringify(this.coll_inventory);
      this.form["delete_inventories"] = JSON.stringify(
        this.deleted_coll_inventory
      );

      let response = await module.submit(this.form, this.route);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.$router.push("/payment-categories/list");
      }
    },
  },

  mounted() {
    this.getType();
    this.getService();
    this.getDataMedicine();
    this.getDataInventory();

    this.$root.$on("updateService", (value) => {
      this.selectedItem = value;
    });
    this.$root.$on("updateDataInvetoryItem", () => {
      this.getDataInventory();
    });
  },
};
</script>

<style scoped>
.bg-light-primary:hover {
  background-color: #e1f0ffa6 !important;
}
</style>